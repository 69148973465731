<template>
  <div>
    <AddEditSuspensionsDialog
      v-model="showEditDialog"
      @input="(newValue) => { showEditDialog = newValue }"
      :suspension="itemToEdit"
      :title="$t('suspensions.dialog.edit-title')"
      @confirmed="onEditConfirmed"
      :isBusy="isBusySaving"
      :validationErrors="editValidationErrors"
    />

    <v-data-table
      :items="items"
      :server-items-length="items.length"
      :options.sync="options"
      :multi-sort="true"
      :headers="computedHeaders"
      :loading="loading"
      :features="features"
      disable-pagination
      hide-default-footer
      dense
      class="elevation-2 rounded-0"
    >
      <!-- Title & Actions -->
      <template v-if="title != null" v-slot:top>
        <v-toolbar flat class="pa-0">
          <v-toolbar-title v-if="title != null" class="ms-8">{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <!-- Custom cells -->
      <template v-slot:[`item.reason`]="{item}">
        {{ toDisplayReason(item.reason, 25) }}
      </template>
      <template v-slot:[`item.status`]="{item}">
        <SuspensionStatusIcon :suspension="item"/>
      </template>
      <template v-slot:[`item.until`]="{item}">
        <DateTimeDisplay
          v-if="item.until"
          :date="item.until"
        />
        <span v-else>∞</span>
      </template>
      <template v-slot:[`item.isActive`]="{item}">
        <v-progress-circular
          v-if="isBusyActivating"
          indeterminate
          color="info"
          size="25"
          width="2"
        />
        <v-checkbox
          v-else
          v-model="item.isActive"
          @change="activeChanged(item)"
          hide-details
          class="ma-0 pa-0"
          :disabled="isBusyActivating || (!canActivate) || isCurrentUser"
        />
      </template>

      <!-- Custom actions -->
      <template v-slot:[`item.actions`]="{item}">
        <v-btn
          icon
          @click="editClicked(item)"
          color="orange"
          v-if="canEdit && !isCurrentUser"
        >
          <v-icon>
            mdi-square-edit-outline
          </v-icon>
        </v-btn>
        <v-btn
          icon
          @click="deleteClicked(item)"
          color="red darken-2"
          :loading="isDeleting"
          v-if="canDelete && !isCurrentUser"
        >
          <v-icon>
            mdi-delete-forever
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import AddEditSuspensionsDialog from '@/components/dialogs/AddEditSuspensionsDialog'
import DateTimeDisplay from '@/components/DateTimeDisplay'
import Sorting from '@/models/sorting'
import SuspensionStatusIcon from '@/components/SuspensionStatusIcon'

export default {
  components: { AddEditSuspensionsDialog, DateTimeDisplay, SuspensionStatusIcon },
  emits: ['itemDeleted', 'itemUpdated', 'sortingChanged'],
  props: {
    /**
     * The items to display.
     */
    items: { type: Array, required: true },
    /**
     * The C(R)UD features to enable for this table.
     * Supply with an array of the desired features ('activate', 'update', 'delete').
     */
    features: { type: Array, default: () => ['activate', 'update', 'delete'] },
    title: { type: String, default: null },
    /**
     * The headers and columns to display.
     */
    headers: { type: Array },
    loading: { type: Boolean, default: false }
  },
  data () {
    return {
      showEditDialog: false,
      showDeleteDialog: false,
      itemToEdit: {},
      isDeleting: false,
      isBusyActivating: false,
      isBusySaving: false,
      editValidationErrors: {},
      options: {}
    }
  },
  computed: {
    ...mapGetters('account', {
      currentUserId: 'id'
    }),
    canActivate () {
      return this.features && this.features.includes('activate')
    },
    canEdit () {
      return this.features && this.features.includes('update')
    },
    canDelete () {
      return this.features && this.features.includes('delete')
    },
    isCurrentUser () {
      return this.itemToEdit.userId === this.currentUserId
    },
    computedHeaders () {
      // Both the injected (prop) and default headers must be wrapped in a computed
      // to ensure translations don't require a page refresh.
      return this.headers && this.headers.length > 0 ? this.headers : this.defaultHeaders
    },
    defaultHeaders () {
      return [
        { text: '', value: 'status', sortable: false },
        { text: this.$t('suspensions.table.columns.reason'), value: 'reason' },
        { text: this.$t('suspensions.table.columns.until'), value: 'until' },
        { text: this.$t('suspensions.table.columns.active'), value: 'isActive' },
        { text: '', value: 'actions', sortable: false, align: 'right' }
      ]
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('suspensions', ['updateSuspension']),
    editClicked (item) {
      this.itemToEdit = item
      this.showEditDialog = true
    },
    async onEditConfirmed (suspension) {
      this.isBusySaving = true
      this.editValidationErrors = {}

      const payload = {
        id: this.itemToEdit.id,
        userId: this.itemToEdit.userId,
        userGroupId: this.itemToEdit.userGroupId,
        isActive: this.itemToEdit.isActive,
        isIndefinitely: suspension.isIndefinitely,
        until: suspension.until,
        reason: suspension.reason
      }
      try {
        const updatedSuspension = await this.updateSuspension(payload)
        this.$emit('itemUpdated', updatedSuspension)
        // Show message indicating save succeeded.
        this.showSnackbar({
          role: 'success',
          messages: [this.$t('common.save-success-message', { entityName: this.$t('common.suspension') })],
          duration: 5000
        })
        this.showEditDialog = false
      } catch (error) {
        console.error(error)
        if (error.response.status === 400 && error.response && error.response.data && error.response.data.errors) {
          this.editValidationErrors = error.response.data.errors
        } else {
          this.showSnackbar({
            role: 'error',
            messages: [this.$t('suspensions.errors.save-failed')],
            duration: 5000
          })
        }
      } finally {
        this.isBusySaving = false
      }
    },
    async deleteClicked (item) {
      try {
        this.isDeleting = true
        await axios.delete(`suspensions/${item.id}`)
        this.$emit('itemDeleted', item)
        // Show message indicating delete succeeded.
        this.showSnackbar({
          role: 'success',
          messages: [this.$tc('common.delete-success-message', 1, { entityName: this.$t('common.suspension').toLowerCase() })],
          duration: 5000
        })
      } catch (error) {
        console.error(error)
        if (error.response.status === 400 && error.response && error.response.data && error.response.data.errors) {
          const deleteValidationErrors = this.validationErrorsToArray(error.response.data.errors)
          if (deleteValidationErrors.length > 0) {
            this.showSnackbar({
              role: 'error',
              messages: deleteValidationErrors,
              duration: 5000
            })
            return
          }
        }

        this.showSnackbar({
          role: 'error',
          messages: [this.$t('suspensions.errors.delete-failed')],
          duration: 5000
        })
      } finally {
        this.isDeleting = false
      }
    },
    async activeChanged (item) {
      this.isBusyActivating = true
      this.patchValidationErrors = {}
      this.showPatchValidationErrors = false

      const patchDocument = [
        {
          op: 'replace',
          path: '/isActive',
          value: item.isActive
        }
      ]
      try {
        await axios.patch(`suspensions/${item.id}`, patchDocument)
        this.$emit('itemUpdated', item)
        // Show message indicating save succeeded.
        this.showSnackbar({
          role: 'success',
          messages: [this.$t('common.save-success-message', { entityName: this.$t('common.suspension') })],
          duration: 5000
        })
      } catch (error) {
        console.error(error)
        item.isActive = !item.isActive
        if (error.response.status === 400 && error.response && error.response.data && error.response.data.errors) {
          const patchValidationErrors = this.validationErrorsToArray(error.response.data.errors)
          if (patchValidationErrors.length > 0) {
            this.showSnackbar({
              role: 'error',
              messages: patchValidationErrors,
              duration: 5000
            })
            return
          }
        }

        this.showSnackbar({
          role: 'error',
          messages: [this.$t('suspensions.errors.save-failed')],
          duration: 5000
        })
      } finally {
        this.isBusyActivating = false
      }
    },
    toDisplayReason (reason, maxLength) {
      return reason.length <= maxLength ? reason : reason.substring(0, maxLength - 4).concat('...')
    },
    validationErrorsToArray (validationErrorObject) {
      return Object.entries(validationErrorObject).reduce((result, [key, errors]) => {
        return result.concat(errors)
      }, [])
    }
  },
  watch: {
    options: {
      immediate: false,
      handler (newValue) {
        this.$emit('sortingChanged', new Sorting(this.options.sortBy, this.options.sortDesc))
      }
    }
  }
}
</script>
