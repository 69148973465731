<template>
  <v-tooltip right>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        :color="status.iconColor"
        dense
        v-bind="attrs"
        v-on="on"
      >
        {{ status.icon }}
      </v-icon>
    </template>
    <span>{{ status.tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    suspension: { type: Object, required: true }
  },
  computed: {
    isExpired () {
      if (this.suspension.until == null || this.suspension.until === undefined) {
        return true
      }
      const until = new Date(this.suspension.until)
      const today = new Date()
      return today > until
    },
    status () {
      if (!this.suspension.isActive) {
        return {
          tooltip: this.$t('suspensions.status-tooltips.inactive'),
          icon: 'mdi-lock-open-check',
          iconColor: 'success darken-2'
        }
      } else if (this.suspension.isIndefinitely) {
        return {
          tooltip: this.$t('suspensions.status-tooltips.indefinitely'),
          icon: 'mdi-lock',
          iconColor: 'error darken-4'
        }
      } else if (this.isExpired) {
        return {
          tooltip: this.$t('suspensions.status-tooltips.expired'),
          icon: 'mdi-lock-open-check',
          iconColor: 'success darken-2'
        }
      } else {
        return {
          tooltip: this.$t('suspensions.status-tooltips.temporary'),
          icon: 'mdi-lock',
          iconColor: 'warning darken-2'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/**
  Tooltips blend in with the background of a hovered row.
  This makes the tooltips a bit darker, so they are easier to see.
 */
.v-tooltip__content {
  background: black !important;
}
</style>
