<template>
  <!-- Suspend dialog -->
  <ConfirmDialog
    v-model="showDialog"
    @input="(newValue) => { showDialog = newValue }"
    :title="title"
    :warning="warning"
    @confirmed="onConfirmed"
    :isBusy="isBusy"
  >
    <template #body>
      <ErrorsDisplay
        :errors="otherValidationErrors"
        class="ma-4"
      />

      <v-radio-group
        v-model="suspensionType"
        mandatory
      >
        <v-radio
          :label="$t('suspensions.dialog.indefinitely-label')"
          value="indefinitely"
        />
        <v-radio
          :label="$t('suspensions.dialog.temporary-label')"
          value="temporary"
        />
      </v-radio-group>

      <!-- Date -->
      <v-menu
        v-if="showDateField"
        ref="dateUntilPicker"
        v-model="datePickerVisible"
        :close-on-content-click="false"
        :return-value.sync="confirmedDateUntil"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="confirmedDateUntil"
            :label="$t('common.until')"
            prepend-icon="mdi-calendar"
            readonly
            clearable
            v-bind="attrs"
            v-on="on"
            :error-messages="validationErrors['until']"
            :error-count="3"
          />
        </template>
        <v-date-picker
          v-model="dateUntil"
          scrollable
          no-title
          show-current
          show-adjacent-months
          elevation="4"
          :min="minDateUntil"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="datePickerCanceled"
          >
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="datePickerConfirmed"
          >
            {{ $t('common.buttons.ok') }}
          </v-btn>
        </v-date-picker>
      </v-menu>

      <v-textarea
        v-model="reason"
        :label="$t('common.reason')"
        rows="3"
        auto-grow
        counter
        :error-messages="validationErrors['reason']"
        :error-count="3"
      />
    </template>
  </ConfirmDialog>
</template>

<script>
import moment from 'moment-timezone'
import { mapState } from 'vuex'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog'
import ErrorsDisplay from '@/components/ErrorsDisplay'

export default {
  name: 'AddEditSuspensionsDialog',
  components: { ConfirmDialog, ErrorsDisplay },
  emits: ['confirmed', 'input'],
  props: {
    /**
     * Indicates whether this dialog should be shown right now.
     * Use the @input event to listen for the dialog to be closed.
     */
    value: { type: Boolean, required: true },
    /**
     * The suspension to edit, null means new suspensions.
     */
    suspension: { type: Object, default: null },
    /**
     * The title to display in the dialog.
     */
    title: { type: String, default: '', required: true },
    /**
     * The warning to display in the dialog.
     */
    warning: { type: String, default: '' },
    /**
     * Whether the dialog is currently busy.
     */
    isBusy: { type: Boolean, default: false },
    /**
     * The validation errors if any are returned by the server.
     */
    validationErrors: { type: Object, default: () => {} }
  },
  data () {
    return {
      suspensionType: 'indefinitely',
      confirmedDateUntil: null,
      dateUntil: null,
      datePickerVisible: false,
      reason: ''
    }
  },
  computed: {
    ...mapState('preferences', ['timezone']),
    showDialog: {
      get: function () { return this.value },
      set: function (newValue) { this.$emit('input', newValue) }
    },
    isNew () {
      return this.suspension == null
    },
    showDateField () {
      return this.suspensionType === 'temporary'
    },
    minDateUntil () {
      return new Date().toISOString().substr(0, 10)
    },
    otherValidationErrors () {
      const otherKeys = ['userId', 'userGroupId']
      return otherKeys.reduce((result, key) => {
        return this.validationErrors[key]
          ? result.concat(this.validationErrors[key])
          : result
      }, [])
    }
  },
  methods: {
    onConfirmed () {
      this.$emit('confirmed', {
        reason: this.reason,
        isIndefinitely: this.suspensionType === 'indefinitely',
        // The suspension will last until 00:00 on the specified date in the timezone of the current user.
        until: this.suspensionType === 'indefinitely' ? null : moment.tz(this.confirmedDateUntil, this.timezone).format()
      })
    },
    datePickerConfirmed () {
      this.$refs.dateUntilPicker.save(this.dateUntil)
      this.confirmedDateUntil = this.dateUntil
    },
    datePickerCanceled () {
      this.datePickerVisible = false
      this.dateUntil = this.confirmedDateUntil
    }
  },
  watch: {
    // Watch for value (v-model) changes. Occurs when the dialog is opened/closed.
    value: {
      immediate: true,
      handler (newVal, oldVal) {
        // Only update the values if the dialog is being opened.
        if (newVal) {
          this.reason = this.suspension?.reason ? this.suspension.reason : ''
          this.suspensionType = this.suspension && !this.suspension.isIndefinitely ? 'temporary' : 'indefinitely'
          this.confirmedDateUntil = this.suspension?.until ? this.suspension.until : null
          this.dateUntil = this.suspension?.until ? this.suspension.until : null
          this.datePickerVisible = false
        }
      }
    }
  }
}
</script>
